import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import {
  AppBar,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import {
  addCalendarSetting,
  deleteCalendarSetting,
  editCalendarSetting,
  editCalendarSettingAssignee,
  getCalendarSettingAssignee,
} from "../../redux/Calendar/action";
import { mechanicDetailsList } from "../../redux/appointment/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  pl: 3,
  pr: 0,
  pb: 3,
};
const breadcrumbStyle = {
  marginBottom: "8px",
};
function CalendarSetting(props) {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showLoader, setShowLoader] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [isEdit, setIsEdit] = useState({ edit: false, id: "" });
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [defaultAssignee, setDefultAssignee] = useState(null);
  const [defaultAssigneeSkip, setDefultAssigneeSkip] = useState(false);

  useEffect(() => {
    fetchCalendarSettings();
    fetchDefaultSetting();
  }, []);
  const fetchCalendarSettings = async () => {
    setShowSkeleton(true);
    await dispatch(mechanicDetailsList());
    setShowSkeleton(false);
    setShowLoader(false);
  };
  const settings = useSelector(
    (state) => state.appointment.mechanicDetailsData
  );

  const fetchDefaultSetting = async () => {
    setShowLoader(true);
    const resp = await dispatch(getCalendarSettingAssignee());
    console.log("🚀 ~ 0 ~ resp:", resp);
    if (resp) {
      setDefultAssigneeSkip(resp?.data?.skipAssigneeSelection);
    }

    setShowLoader(false);
  };

  useEffect(() => {
    const defaultAssignee =
      Array.isArray(settings) && settings?.find((d) => d.isDefault == true);
    console.log("🚀 ~ useEffect ~ defaultAssignee:", defaultAssignee);

    setDefultAssignee(defaultAssignee?._id);
  }, [settings]);
  // console.log("settings, settings",settings);
  const handleClose = () => {
    setOpen(false);
    setIsEdit({ edit: false, id: "" });
    reset();
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setDeleteId(null);
  };

  const onSubmit = async (values) => {
    setShowLoader(true);

    if (isEdit?.edit) {
      const resp = await dispatch(
        editCalendarSetting({ id: isEdit?.id, name: values })
      );
    } else {
      const resp = await dispatch(addCalendarSetting(values));
    }
    fetchCalendarSettings();
    handleClose();
    // console.log("values", values);
  };

  const handleDelete = async () => {
    setShowLoader(true);
    await dispatch(deleteCalendarSetting({ id: deleteId }));
    fetchCalendarSettings();
    handleCloseDelete();
    handleClose();
  };
  const handleChangeAssignee = async (event) => {
    let payload = { defaultAssigneeId: event.target.value };
    setShowLoader(true);
    const resp = await dispatch(editCalendarSettingAssignee(payload));
    if (resp) {
      setDefultAssignee(event.target.value);
      toast.success("Changes Saved");
      fetchCalendarSettings();
    }
    setShowLoader(false);
    console.log("value", event.target.value);
  };
  const handleChangeSkipSelection = async (value) => {
    let payload = {
      skipAssigneeSelection: value,
      defaultAssigneeId: defaultAssignee,
    };
    setShowLoader(true);
    const resp = await dispatch(editCalendarSettingAssignee(payload));
    if (resp) {
      setDefultAssigneeSkip(value);
      toast.success("Changes Saved");
    }
    setShowLoader(false);
    console.log("value", value);
  };

  const Skeletons = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <Grid item md={12} sm={12} key={index}>
        <div
          style={{
            background: "#F1F1F1",
            padding: "21px 16px",
            borderRadius: "4px",
          }}
        >
          <Skeleton animation="wave" variant="text" />
        </div>
      </Grid>
    ));
  };
  return (
    <div>
      <Box padding={"24px 24px 24px 20px"}>
        <Grid container rowGap={0}>
          <Grid container item justifyContent={"space-between"}>
            <Grid item lg={8} display={"flex"} columnGap={3}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 170 }}>
                <InputLabel
                  id="demo-simple-select-standard-label"
                  shrink={Boolean(defaultAssignee)}
                >
                  Set as Default
                  <Tooltip title="default selection">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={defaultAssignee || ""}
                  onChange={handleChangeAssignee}
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent", // Removes the grey outline when focused but not selected
                    },
                    "&.MuiSelect-select:focus": {
                      backgroundColor: "transparent", // Prevents grey background on focus
                    },
                  }}
                  // label=" Set as Default"
                >
                  {Array.isArray(settings) &&
                    settings?.map((data) => (
                      <MenuItem key={data?._id} value={data?._id}>
                        {data?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControlLabel
                control={
                  <Switch
                    // defaultChecked
                    checked={defaultAssigneeSkip}
                    onChange={(event) => {
                      handleChangeSkipSelection(event.target.checked);
                    }}
                  />
                }
                label={
                  <div>
                    Skip Calendar Selection
                    <Tooltip
                      title={
                        <div style={{ fontSize: "13px" }}>
                          Skip calendar assignment for appointment requests and
                          automatically assign to the default calendar
                        </div>
                      }
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
              />
            </Grid>
            <Grid
              item
              lg={4}
              justifyContent={"end"}
              display={"flex"}
              alignItems={"center"}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{ float: "right" }}
                onClick={() => setOpen(true)}
              >
                + New Calendar
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent={"space-between"}
            spacing={2}
            mt={"24px"}
          >
            {!showSkeleton &&
              Array.isArray(settings) &&
              settings.map((d) => {
                return (
                  <Grid item md={12} sm={12} key={d._id} sx={{ width: "100%" }}>
                    <div
                      style={{
                        background: "#F1F1F1",
                        padding: "21px 16px",
                        borderRadius: "4px",
                      }}
                    >
                      <Stack spacing={3} direction="row">
                        <div
                          style={{
                            width: "100%",
                            alignItems: "center",
                            display: "flex",
                            fontSize: "20px",
                            fontWeight: 500,
                          }}
                        >
                          {d.name}
                          {d?.isDefault && (
                            <Chip
                              style={{
                                background: "rgba(0, 0, 0, 0.08)",
                                color: "black",
                                marginLeft: "24px",
                              }}
                              label="Default"
                            ></Chip>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => {
                              setIsEdit({ edit: true, id: d._id });
                              setOpen(true);
                              setValue("name", d.name);
                            }}
                          >
                            EDIT
                          </Button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            size="large"
                            onClick={() => {
                              setOpenDelete(true);
                              setDeleteId(d._id);
                            }}
                          >
                            DELETE
                          </Button>
                        </div>
                      </Stack>
                    </div>
                  </Grid>
                );
              })}
            {showSkeleton && <Skeletons rowsNum={4} />}
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "40vw" }}>
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                {isEdit?.edit ? "Edit " : "New "}
                Calendar
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handleClose}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ fontSize: 35, color: "#0000008F" }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            disableGutters
            style={{ marginTop: "72px", paddingRight: "24px", paddingLeft: 0 }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} xl={12}>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 500 }}
                    pb={1}
                  >
                    Calendar Name
                    {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                  </Typography>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: "Calendar name is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ""}
                        {...field}
                      />
                    )}
                  />
                  {/* <TextField
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                          /> */}
                </Grid>

                <Grid
                  item
                  container
                  justifyContent={"space-between"}
                  // style={{ marginTop: "8px" }}
                >
                  <Grid item>
                    <Stack spacing={2} direction="row">
                      {isEdit?.edit && (
                        <Button
                          variant="contained"
                          color="error"
                          //   onClick={handleDeleteVehicle}
                          onClick={() => {
                            handleClose();
                            setOpenDelete(true);
                            setDeleteId(isEdit?.id);
                          }}
                          size="large"
                        >
                          DELETE CALENDAR
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                        size="large"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={handleClose}
                        size="large"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiDialog-paper": { width: "545px", maxWidth: "none" } }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontWeight: "500",
            fontSize: "20px",
            color: "#000000DE",
            // height: "64px",
          }}
          className="deleteDialog"
        >
          {"Are you Sure?"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDelete}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon
            sx={{ width: "35px", height: "35px", fill: "#000000DE" }}
          />
        </IconButton>
        <Divider />
        <DialogContent sx={{ paddingTop: "16px" }}>
          <DialogContentText id="alert-dialog-description" color={"#000000"}>
            The selected calendar will be deleted and all existing scheduled
            appointments will shift to default calendar.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "16px" }}>
          <Button onClick={handleCloseDelete} variant="outlined">
            CANCEL
          </Button>
          <Button
            onClick={handleDelete}
            autoFocus
            color="error"
            variant="contained"
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CalendarSetting;
