import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Skeleton, TablePagination, TableSortLabel } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { carfaxVehicleHistory } from "../../redux/Customer/action";

export default function CarfaxHistoryTable({ vehicleData, open }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let params = {
    page: page + 1,
    limit: rowsPerPage,
  };
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(true);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPaginatedData(
      data.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage)
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPaginatedData(
      data.slice(
        0 * parseInt(event.target.value, 10),
        0 * parseInt(event.target.value, 10) + parseInt(event.target.value, 10)
      )
    );
  };

  const fetchCarfaxHistory = async ({ vin, licenseNumber, province }) => {
    setLoading(true);
    const response = await dispatch(
      carfaxVehicleHistory({ vin, licenseNumber, province })
    );
    if (response) {
      setData(response?.serviceHistory?.displayRecords);
      setPaginatedData(
        response?.serviceHistory?.displayRecords.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      if (
        vehicleData &&
        vehicleData?.item?.vin &&
        vehicleData?.item?.vin !== ""
      ) {
        fetchCarfaxHistory({
          vin: vehicleData?.item?.vin,
          licenseNumber: null,
          province: null,
        });
        return;
      }
      if (
        vehicleData &&
        vehicleData?.item?.licenseNumber !== "" &&
        vehicleData?.item?.provinceCode !== ""
      ) {
        fetchCarfaxHistory({
          vin: null,
          licenseNumber: vehicleData?.item?.licenseNumber,
          province: vehicleData?.item?.provinceCode,
        });
        return;
      }
    }
  }, [vehicleData, open]);
  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        {/* <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell> */}
      </TableRow>
    ));
  };

  const handleSort = () => {
    setSort(!sort);
    data.sort(function (a, b) {
      return sort
        ? new Date(b.displayDate) - new Date(a.displayDate)
        : new Date(a.displayDate) - new Date(b.displayDate);
    });
    setPaginatedData(
      data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  };
  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: "400px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={true}
                  direction={sort ? "desc" : "asc"}
                  sx={{ fontWeight: 500 }}
                  onClick={() => {
                    handleSort();
                  }}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">Odometer</TableCell>
              {/* <TableCell align="left">Source</TableCell> */}
              <TableCell align="left">Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <TableRowsLoader rowsNum={5} />}
            {!loading &&
              paginatedData.map((row) => (
                <TableRow
                  key={row.displayDate}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {dayjs(row.displayDate).format("YYYY MMM DD")}
                  </TableCell>
                  <TableCell align="left">
                    {row.odometer ? row.odometer + " KM" : ""}{" "}
                  </TableCell>
                  {/* <TableCell align="left">{row.fat}</TableCell> */}
                  <TableCell align="left">
                    {row?.text[0] == "Vehicle serviced" && (
                      <>
                        <b>Vehicle serviced</b>
                        <br />
                      </>
                    )}
                    <ul style={{ padding: 0, margin: 0, paddingLeft: "17px" }}>
                      {row?.text?.map((d) => {
                        if (d == "Vehicle serviced") {
                          return;
                        } else {
                          return <li>{d}</li>;
                        }
                      })}
                    </ul>
                    {/* <br />
                  
                  <ul style={{ padding: 0, margin: 0, paddingLeft: "17px" }}>
                    <li>shdksd</li>
                    <li>sbdhk</li>
                  </ul> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container item alignItems={"center"} justifyContent="end">
        <Grid item>
          {/* {customers1?.pagination?.totalCount > 0 && ( */}
          <TablePagination
            component="div"
            count={data?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* )} */}
        </Grid>
      </Grid>
    </>
  );
}
