import React from "react";
import {
  AppBar,
  Box,
  Container,
  Modal,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CalendarSetting from "./CalendarSettingContainer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 900,

  bgcolor: "background.paper",
  borderRadius: "2px solid ",
  boxShadow: 24,
  outline: "none",
};
function CalendarSettingModel({ open, handleCloseModel = () => {} }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModel}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            // width: "41vw",
            // width: "100%",
            // maxWidth: "50vw",
            // maxHeight: "90vh",
            // overflow: "hidden",
            // overflowY: "scroll",
          }}
          //   className="modelSize"
        >
          <AppBar
            sx={{ background: "white" }}
            variant="outlined"
            handleCloseModel
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"black"}>
                Calendar Settings
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handleCloseModel}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon
                    style={{
                      fontSize: 32,
                      fill: "#000000DE",
                    }}
                  />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            // className="viewVehicleModal"
            style={{
              marginTop: "64px",
              maxHeight: "67vh",
              overflow: "auto",
            }}
          >
            <CalendarSetting />
          </Container>
        </Box>
      </Modal>
    </div>
  );
}

export default CalendarSettingModel;
